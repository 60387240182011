<template>
    <div class='app-container'>
        <el-page-header @back="goBack" class="header">
            <template slot="content">
                模板定制：{{first}}-{{second}}
            </template>
        </el-page-header>
        <el-divider class="margin10"></el-divider>
        <vxe-toolbar>
            <template #buttons>
                <el-button type="success" size="mini" icon="el-icon-upload2" @click="uploadHandle()">上传评阅书模板
                </el-button>
                <el-button type="success" size="mini" icon="el-icon-download" @click="downloadHandle()">下载评阅书模板
                </el-button>
                <el-button type="success" size="mini" icon="el-icon-camera" @click="testHandle()">测试论文模板
                </el-button>
            </template>
        </vxe-toolbar>
        <el-container>
            <el-aside width='600px'>
                <el-card class="left">
                    <el-divider content-position="left">论文信息</el-divider>
                    <el-row :gutter="40">
                        <el-col v-for="item in paperInfos" :key="item.name" :span="8">
                            <div class="box" @click="copyHandle(item.name)">
                                ${ {{item.name}} }
                            </div>
                            <div class="box-text">
                                {{item.description}}
                            </div>
                        </el-col>
                    </el-row>
                    <el-divider content-position="left">专家信息</el-divider>
                    <el-row :gutter="40">
                        <el-col v-for="item in expertInfos" :key="item.name" :span="8">
                            <div class="box" @click="copyHandle(item.name)">
                                ${ {{item.name}} }
                            </div>
                            <div class="box-text">
                                {{item.description}}
                            </div>
                        </el-col>
                    </el-row>
                </el-card>
            </el-aside>
            <el-main style="padding:0px;" class="right">
                <el-card>
                    <el-divider content-position="left">评审信息</el-divider>
                </el-card>
                <div>
                    <!-- 数值 -->
                    <vxe-table border align="center" ref="numberTable" style="width: 100%;" :span-method="mergeMethod"
                        :data="numberTable" element-loading-text="拼命加载中"  element-loading-spinner="el-icon-loading">
                        <vxe-column field="firstIndex" title="一级指标" width="30%">
                            <template #default="{row}">
                                {{row.firstIndex}}
                            </template>
                        </vxe-column>
                        <vxe-column field="secondIndex" title="二级指标" width="50%">
                            <template #default="{row}">
                                {{row.secondIndex}} （{{row.score}} 分）
                            </template>
                        </vxe-column>
                        <vxe-column field="content" title="分数" width="20%">
                            <template #default="{row}">
                                <!-- <vxe-input v-model="row.content" type="number" min="0" :max="row.score"
                                    @change='contentChange' clearable>
                                </vxe-input> -->
                                <div class="box-table" @click="copyHandle(row.content)">
                                    ${ {{row.content}} }
                                </div>
                            </template>
                        </vxe-column>
                    </vxe-table>
                    <vxe-table :show-header="false" border align="center" ref="totalTable" style="width: 100%;"
                        :span-method="mergeMethod" :data="totalTable" element-loading-text="拼命加载中"
                        element-loading-spinner="el-icon-loading">
                        <vxe-column field="firstIndex" title="一级指标" width="80%">
                            <template #default="{row}">
                                {{row.firstIndex}}（{{row.score}} 分）
                            </template>
                        </vxe-column>
                        <vxe-column field="content" title="分数" width="20%">
                            <template #default="{row}">
                                <div class="box-table" @click="copyHandle(row.content)">
                                    ${ {{row.content}} }
                                </div>
                            </template>
                        </vxe-column>
                    </vxe-table>
                    <!-- 单选 -->
                    <vxe-table border align="center" ref="singleTable" style="width: 100%;" :span-method="mergeMethod"
                        :data="singleTable" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading">
                        <vxe-column field="firstIndex" title="指标" width="30%">
                            <template #default="{row}">
                                {{row.firstIndex}}
                            </template>
                        </vxe-column>
                        <vxe-column field="content" title="选项" width="70%" align="left">
                            <template #default="{row}">
                                <!-- <vxe-radio v-model="groupSelected[row.group]" size="medium" :label="row.secondIndex"></vxe-radio> -->
                                <span class="box-table" @click="copyHandle(row.content)">
                                    ${ {{row.content}} }
                                </span> {{row.score}}

                            </template>
                        </vxe-column>
                    </vxe-table>

                    <!-- 多选 -->
                    <vxe-table v-if="mulTable.length" border align="center" ref="mulTable" style="width: 100%;"
                        :span-method="mergeMethod" :data="mulTable" element-loading-text="拼命加载中"
                        element-loading-spinner="el-icon-loading">
                        <vxe-column field="firstIndex" title="指标" width="30%">
                            <template #default="{row}">
                                {{row.firstIndex}}
                            </template>
                        </vxe-column>
                        <vxe-column field="content" title="选项" width="70%" align="left">
                            <template #default="{row}">
                                <span class="box-table" @click="copyHandle(row.content)">
                                    ${ {{row.content}} }
                                </span> {{row.score}}
                            </template>
                        </vxe-column>
                    </vxe-table>

                    <!-- 文本 -->
                    <div v-for="(item,index) in textTable">
                        <div class="txtareaCom">
                            <span style="color:#000">{{item.firstIndex}} </span><span>{{'（'+item.comment + '）'}}</span>
                        </div>
                        <div class="comment-box" @click="copyHandle(item.content)">
                            ${ {{item.content}} }
                        </div>
                    </div>
                    <div v-for="(item,index) in commentTable">
                        <div class="txtareaCom">
                            {{item.description}}
                            <!-- {{item.comment}} -->
                        </div>
                    </div>
                    <!-- <div class="btn">
                        <el-button type="primary" @click="HandleSaveReview" size="medium">保存评审结果</el-button>
                        <el-button type="primary" @click="HandlePostReview" size="medium">提交评审结果</el-button>
                    </div> -->
                </div>
            </el-main>
        </el-container>

        <vxe-modal v-model="uploadModel" :position="{top: '0px'}" width="500" :show-footer='true' show-zoom resize
            :transfer='true'>
            <template #title>
                上传评阅书模板
            </template>
            <el-upload ref='templateFile' class="upload-demo" drag action="#" :auto-upload='false'>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">只能上传docx文件</div>
            </el-upload>
            <template #footer>
                <el-button @click="cfmInsertReivewTypeHandle" type="primary" size="small">确认</el-button>
            </template>
        </vxe-modal>
    </div>
</template>

<script>
    import {
        Form
    } from 'vxe-table';
    export default {
        data() {
            return {
                uploadModel: false,
                paperInfos: [],
                expertInfos: [],
                numberTable: [],
                totalTable: [],
                singleTable: [],
                mulTable: [],
                textTable: [],
                commentTable: [],
                curMark: {
                    title: '数值型',
                    type: 'number',
                    field: 'field'
                },
                first: '',
                second: ''

            }
        },
        created() {
            this.templateId = this.$route.params.id;
            this.first = this.$store.state.custom.institution.first;
            this.second = this.$store.state.custom.institution.second;
            this.getReviewPaperInfos();
            this.getReviewExpertInfos();
            this.getReviewInfo();
        },
        
        methods: {
            // 获取论文信息
            getReviewPaperInfos() {
                this.$api.reviewCustom.getReviewPaperInfos()
                    .then(res => {
                        if (res.data.code == 200) {
                            this.paperInfos = res.data.data;
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护！");
                    })
            },
            // 获取专家信息
            getReviewExpertInfos() {
                this.$api.reviewCustom.getReviewExpertInfos()
                    .then(res => {
                        if (res.data.code == 200) {
                            this.expertInfos = res.data.data;
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护！");
                    })
            },
            getReviewInfo() {
                let params = {
                    templateId: this.templateId
                };
                this.$api.reviewCustom.getReviewInfo(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            let tem = res.data.data;
                            this.numberTable = tem['0'] ? tem['0'] : [];
                            this.singleTable = tem['2'] ? tem['2'] : [];
                            this.mulTable = tem['3'] ? tem['3'] : [];
                            this.totalTable = tem['4'] ? tem['4'] : [];
                            this.textTable = tem['5'] ? tem['5'] : [];
                            this.commentTable = tem['6'] ? tem['6'] : [];
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护");
                    })
            },
            uploadHandle() {
                this.uploadModel = true;
            },
            cfmInsertReivewTypeHandle() {
                if (this.$refs.templateFile.uploadFiles.length < 1) {
                    this.$message.warning("请选择模板文件");
                    return;
                }
                const loading = this.$loading({
                    lock: true,
                    text: '上传中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                let rawFile = this.$refs.templateFile.uploadFiles[0];
                let params = new FormData();
                params.append("templateId", this.templateId);
                params.append("file", rawFile.raw);
                this.$api.reviewCustom.postUploadPatternFile(params)
                    .then(res => {
                        loading.close();
                        if (res.data.code == 200) {
                            this.$message.success("上传成功！");
                            this.uploadModel = false;
                        } else {
                            this.$message.warning("上传失败！");
                        }

                    })
                    .catch(err => {});
            },
            downloadHandle() {
                const loading = this.$loading({
                    lock: true,
                    text: '下载中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                // let params = new URLSearchParams();
                // params.append("reviewTemplateld", this.templateId);
                let params = {
                    reviewTemplateId:this.templateId
                }
                this.$api.reviewCustom.templateDownload(params)
                    .then(res => {
                        console.log(res);
                        const blob = new Blob([res.data], {
                                type: 'application/octet-stream;charset=utf-8'
                            });
                        let url = window.URL || window.webkitURL;
                        const href = url.createObjectURL(blob);
                        let downloadElement = document.createElement('a');
                        downloadElement.href = href;
                        let name = res.headers['content-disposition'].split(';')[1].split('=')[1];
                        downloadElement.download = name.slice(1,name.length - 1); //下载后文件名
                        document.body.appendChild(downloadElement);
                        downloadElement.click(); //点击下载
                        document.body.removeChild(downloadElement); //下载完成移除元素
                        window.URL.revokeObjectURL(href); //释放掉blob对象
                        loading.close();
                    })
                    .catch(err => {
                      // this.$message.warning("");
                    });
            },
            testHandle() {
                const loading = this.$loading({
                    lock: true,
                    text: '下载中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                // let params = new URLSearchParams();
                // params.append("reviewTemplateld", this.templateId);
                let params = {
                    reviewTemplateId:this.templateId
                }
                this.$api.reviewCustom.templateFeasibilityVerify(params)
                    .then(res => {
                        console.log(res);
                        const blob = new Blob([res.data], {
                                type: 'application/octet-stream;charset=utf-8'
                            });
                        let url = window.URL || window.webkitURL;
                        const href = url.createObjectURL(blob);
                        let downloadElement = document.createElement('a');
                        downloadElement.href = href;
                        let name = res.headers['content-disposition'].split(';')[1].split('=')[1];
                        downloadElement.download = name.slice(1,name.length - 1); //下载后文件名
                        document.body.appendChild(downloadElement);
                        downloadElement.click(); //点击下载
                        document.body.removeChild(downloadElement); //下载完成移除元素
                        window.URL.revokeObjectURL(href); //释放掉blob对象
                        loading.close();
                    })
                    .catch(err => {});
            },
            copyHandle(name) {
                let field = '${' + name + '}';
                if (navigator.clipboard && window.isSecureContext) {
                    this.$message.success(field + '复制成功')
                    return navigator.clipboard.writeText(field)
                } else {
                    // 创建text area
                    const textArea = document.createElement('textarea')
                    textArea.value = field
                    // 使text area不在viewport，同时设置不可见
                    textArea.style.position = "absolute";
                    textArea.style.opacity = 0;
                    textArea.style.left = "-999999px";
                    textArea.style.top = "-999999px";
                    document.body.appendChild(textArea)
                    textArea.focus()
                    textArea.select()
                    this.$message.success(field + '复制成功1')
                    return new Promise((res, rej) => {
                        // 执行复制命令并移除文本框
                        document.execCommand('copy') ? res() : rej()
                        textArea.remove()
                    })
                }
            },
            goBack() {
                this.$router.push({
                    name: 'reviewBookCustom'
                })
            },
            //合并表格
            mergeMethod({
                row,
                _rowIndex,
                column,
                _columnIndex,
                visibleData
            }) {
                let firstIndex = row['firstIndex'];
                if (column.property == 'firstIndex') {
                    let prevRow = visibleData[_rowIndex - 1];
                    let nextRow = visibleData[_rowIndex + 1];
                    if (prevRow && prevRow['firstIndex'] == firstIndex) {
                        return {
                            rowspan: 0,
                            colspan: 0
                        };
                    } else {
                        let countRowspan = 1;
                        let countColspan = 1;
                        while (nextRow && nextRow['firstIndex'] == firstIndex) {
                            nextRow = visibleData[++countRowspan + _rowIndex]
                        }
                        if (row.type == 5 && _columnIndex == 1) {
                            countColspan = 2;
                        }
                        if (countRowspan >= 1) {
                            return {
                                rowspan: countRowspan,
                                colspan: countColspan
                            };
                        }
                    }
                }
            },
        },
        components: {

        }
    }
</script>

<style scoped>
    .left {
        /* width: 600px; */
        height: calc(100vh - 185px);
        padding: 0px 20px;
        overflow: auto;
    }

    .left::-webkit-scrollbar {
        width: 0 !important
    }

    .right {
        min-width: 600px;
        margin-left: 20px;
        height: calc(100vh - 185px);
        overflow: auto;
    }

    .right::-webkit-scrollbar {
        width: 0 !important
    }

    .box {
        width: 146.6px;
        background-color: aliceblue;
        height: 60px;
        text-align: center;

        line-height: 60px;
        white-space: nowrap;
        text-overflow: ellipsis;
        border-radius: 10px;
        overflow: hidden;
    }

    .box-text {
        padding-top: 10px;
        margin-bottom: 20px;
        text-align: center;
        font-size: 14px;
    }

    .box-table {
        padding: 10px;
        background-color: aliceblue;
    }

    .comment-box {
        border: 1px solid #e8eaec;
        height: 100px;
        line-height: 100px;
        text-align: center;
        background-color: aliceblue;
    }

    .txtareaCom {
        border: 1px solid #e8eaec;
        border-top: 0px;
        padding: 10px;
        color: rgb(160, 160, 160);
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
        font-size: 14px;
    }

    /* .txtarea .el-textarea__inner {
        border: 1px solid rgb(143, 143, 143);
        border-top: 0px;
        border-radius: 0px;
    } */
</style>

<style>
    .margin10.el-divider--horizontal {
        margin: 10px 0px;
    }
</style>